import { configureStore } from '@reduxjs/toolkit'
import ItemReducers from './features/itemsSlice'
import UserReducer from './features/userSlice'
import ChannelReducer from './features/channelSlice'

export const store = configureStore({
    reducer: {
        items: ItemReducers,
        user: UserReducer,
        channel: ChannelReducer
    }
})