import React, { useEffect } from 'react'
import { Item, User } from '../scripts/types'
import { useDispatch } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSnowflake, faStar } from '@fortawesome/free-solid-svg-icons'
import { setCurrentItem } from '../redux/features/itemsSlice'
import { AdminRequests } from '../scripts/admin_requests'
import { useSelector } from 'react-redux'


interface ShopCardProps {
    item: Item
    userData: User,
    isLoggedIn: boolean
}

function ShopCard({ item, userData, isLoggedIn }: ShopCardProps) {

    const IMAGE_PATH = process.env.REACT_APP_BACKEND_URL + "/api/image"
    const dispatcher = useDispatch()
    const accessToken = useSelector((state: any) => state.user.accessToken)

    useEffect(()=>{
        console.log(userData);
        
    },[])

    const freezeUnfreezeItem = (item_id: number, status: boolean) => {
        AdminRequests.freezeUnfreezeItem(
            accessToken,
            item_id,
            status
        )

        .then(res => {
            window.location.reload()
        })
        .catch(err => console.log(err))
    }

    return (
        <div className={item.freezed ? "shop-card freezed-card" : "shop-card"} key={item.id}>
            {item.img &&
                <div className="shop-card-image" style={{ backgroundImage: `url("${IMAGE_PATH}/${item.img}")` }}>

                </div>
            }
            {
                userData.is_admin &&
                <a 
                    onClick={()=>freezeUnfreezeItem(item.id, !item.freezed)}
                    className='admin-freeze-btn'>
                        {item.freezed ? "UNFREEZE" : "FREEZE"}
                </a>
            }
            <div className={!item.img ? "shop-card-metadata metadata-fullwidth" : "shop-card-metadata"}>
                <div className="title">
                    <h3>{item.title}</h3>
                    {
                        item.for_sub &&
                        <span className='only-for-sub'>
                            <FontAwesomeIcon icon={faStar} />
                            <p>ONLY FOR SUB</p>
                        </span>
                    }

                    {
                        item.freezed &&
                        <span className='item-freezed'>
                            <FontAwesomeIcon icon={faSnowflake} />
                            <p>FREEZED</p>
                        </span>

                    }
                </div>

                <p className="description">{item.description}</p>

                <div className="shop-card-info">
                    <p className="price">{item.price}

                        <img className='logo-jestoni' src='img/jestoni.png' />
                    </p>

                    {
                        (!isLoggedIn) ?
                            <a className='not-available-btn'>NON DISPONIBILE</a>

                            :

                            (item.freezed) ?
                                <a className='not-available-btn'>NON DISPONIBILE</a>
                                :
                                (item.for_sub) ?

                                    (
                                        userData.is_subscriber
                                            ?
                                            <a className='buy-btn' onClick={() => {
                                                //setSelectedItem(el)
                                                dispatcher(setCurrentItem(item))
                                            }}>Riscatta</a>
                                            :
                                            <a className='not-available-btn'>NON DISPONIBILE</a>
                                    )
                                    :
                                    <a className='buy-btn' onClick={() => {
                                        //setSelectedItem(el)
                                        dispatcher(setCurrentItem(item))
                                    }}>Riscatta</a>

                    }

                </div>
            </div>
        </div>
    )
}

export default ShopCard