import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Requests from '../scripts/requests'
import { Purchase } from '../scripts/types'
import CircleLoading from '../components/CircleLoading'
import _ from 'lodash'

import "../css/PurchaseHistory.scss"
import { dateFormat } from '../scripts/utils'

function PurchaseHistory() {

    const dispatcher = useDispatch()
    const accessToken: string = useSelector((state: any) => state.user.accessToken)
    const [dataLoading, setDataLoading] = useState(true)
    const [itemsPurchased, setItemsPurchased] = useState<Purchase[]>([])

    useEffect(() => {
        //console.log(accessToken);

        if (!_.isNull(accessToken)) {
            Requests.getUserPurchased(accessToken)
                .then(res => {
                    setItemsPurchased(res)
                })
                .catch(err => console.log(err))
                .finally(() => setDataLoading(false))
        }
    }, [accessToken])

    return (
        <div className='content-wrapper'>
            <h2 className='purchase-history-title'>Storico riscatti</h2>
            {
                dataLoading ?
                    <CircleLoading color='#fff' />
                    :
                    <div className='purchased-list'>
                        {
                            itemsPurchased.map((el, idx) => {
                                return (
                                    <ul className='purchase-item' key={idx}>
                                        <li>
                                            <p><b>OGGETTO </b></p>
                                            <p>{el.item_name}</p>
                                        </li>
                                        <li>
                                            <p><b>COSTO </b></p>
                                            <p>{el.item_price} Jestoni</p>
                                        </li>
                                        <li>
                                            <p><b>RISCATTATO IL </b></p>
                                            <p>{dateFormat(el.purchased_on)}</p>
                                        </li>
                                        <li>
                                            <p><b>ID TRANSAZIONE </b></p>
                                            <p> {el.id}</p>
                                        </li>
                                    </ul>
                                )
                            })
                        }
                    </div>
            }
        </div>
    )
}

export default PurchaseHistory