import "../css/components/MainMenu.scss"
import { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { getWindowSize } from '../scripts/utils'

interface MainMenuProps {
    openMenu: boolean
    setOpenMenu: any
    isLoggedIn: boolean
    userData: any
}

function MainMenu({ openMenu, setOpenMenu, isLoggedIn, userData }: MainMenuProps) {

    const [windowSize, setWindowSize] = useState(getWindowSize());

    return (
        <ul id='main-menu' className={openMenu ? "main-menu-open" : "main-menu"}>
            <NavLink
                className={({ isActive }) => (isActive ? 'btn-menu nav-active' : 'btn-menu')}
                to={"/"} onClick={() => setOpenMenu((prev: any) => !prev)}>
                {/*<FontAwesomeIcon icon={faHome} size='xs' /> */}
                Home
            </NavLink>
            <NavLink
                className={({ isActive }) => (isActive ? 'btn-menu nav-active' : 'btn-menu')}
                to={"/shop"} onClick={() => setOpenMenu((prev: any) => !prev)}>
                {/*<FontAwesomeIcon icon={faShop} size='xs' /> */}
                Shop
            </NavLink>
            {isLoggedIn &&
            <NavLink
                className={({ isActive }) => (isActive ? 'btn-menu nav-active' : 'btn-menu')}
                to={"/purchase-history"} onClick={() => setOpenMenu((prev: any) => !prev)}>
                {/*<FontAwesomeIcon icon={faShop} size='xs' /> */}
                Riscatti
            </NavLink>
            }
            {
                windowSize.innerWidth < 960 && isLoggedIn &&
                <NavLink
                    className={({ isActive }) => (isActive ? 'btn-menu nav-active' : 'btn-menu')}
                    to={"/profile"} onClick={() => setOpenMenu((prev: any) => !prev)}>
                    {/*<FontAwesomeIcon icon={faShop} size='xs' /> */}
                    Profilo
                </NavLink>
            }

            {
                userData.is_admin &&
                <NavLink target='_blank' to={"/admin"} onClick={() => setOpenMenu((prev: any) => !prev)}>
                    Admin
                </NavLink>
            }

            {
                userData.is_admin &&
                <NavLink target='_blank' to={"/admin/pokemon"} onClick={() => setOpenMenu((prev: any) => !prev)}>
                    Pokemon
                </NavLink>
            }

            {
                userData.is_admin &&
                <NavLink target='_blank' to={"/admin/betting"} onClick={() => setOpenMenu((prev: any) => !prev)}>
                    Betting
                </NavLink>
            }

            <div className="social-links">
                {process.env.REACT_APP_YOUTUBE &&
                    <a className="youtube" href={process.env.REACT_APP_YOUTUBE} target='_blank'>
                        <img src='img/social/youtube.png' />
                    </a>
                }

                {process.env.REACT_APP_INSTAGRAM &&
                    <a className="instagram" href={process.env.REACT_APP_INSTAGRAM} target='_blank'>
                        <img src='img/social/instagram.png' />
                    </a>
                }

                {process.env.REACT_APP_TWITCH &&
                    <a className="twitch" href={process.env.REACT_APP_TWITCH} target='_blank'>
                        <img src='img/social/twitch.png' />
                    </a>
                }

            </div>
        </ul>
    )
}

export default MainMenu