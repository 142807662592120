import React, { useEffect } from 'react'

import '../css/components/ProfileTooltip.scss'
import moment from 'moment'
import { TwitchUserInfo, User } from '../scripts/types';
import { Link } from 'react-router-dom';

interface ProfileTooltipProps {
  open: boolean;
  userData?: User,
  isMobile: boolean,
  logout: any,
  closeTooltip: any
}

function ProfileTooltip(props: ProfileTooltipProps) {

  useEffect(() => {
  }, [])

  return (
    <div className={props.open ? 'profile-tooltip profile-tooltip-open' : "profile-tooltip"}>
      <div className={props.open ? 'profile-tooltip-chevron profile-tooltip-chevron-open' : "profile-tooltip-chevron"}></div>
      {props.userData &&
        <>
          <h3 className='profile-tooltip-title'>BILANCIO</h3>

          {props.userData?.coins &&
            <div className='profile-tooltip-balance'>
              <p>{props.userData.coins.toFixed(1)}</p>
              <img className='logo-jestoni' src='img/jestoni.png' />
            </div>
          }
        </>
      }


    </div>
  )
}

export default ProfileTooltip