import axios from "axios";
import { Cookie, CookieSetOptions } from 'universal-cookie';

const BACKEND = process.env.REACT_APP_BACKEND_URL

export default class Requests {

    // POKEMON

    static async getUserPokemonTeam(access_token: string) {
        let result = await axios.get(BACKEND + "/api/pokemon/user/team", {
            headers: {
                "Authorization": "Bearer " + access_token
            }
        })
        if (result.status === 200) {
            return result.data
        }

        return false
    }

    static async removePokemonFromTeam(pokemon_name: string, access_token: string) {
        let result = await axios.delete(BACKEND + "/api/pokemon/user/team/" + pokemon_name, {
            headers: {
                "Authorization": "Bearer " + access_token
            }
        })
        if (result.status === 200) {
            return result.data
        }

        return false
    }

    static async addPokemonToTeam(pokemon_name: string, access_token: string) {
        let result = await axios.post(BACKEND + "/api/pokemon/user/team/" + pokemon_name, null, {
            headers: {
                "Authorization": "Bearer " + access_token
            }
        })
        if (result.status === 200) {
            return result.data
        }

        return false
    }

    static async addPokemonToTeamSlot(pokemon_name: string, slot: number, access_token: string) {
        let result = await axios.post(BACKEND + "/api/pokemon/user/team/" + pokemon_name + "/" + slot, null, {
            headers: {
                "Authorization": "Bearer " + access_token
            }
        })
        if (result.status === 200) {
            return result.data
        }

        return false
    }

    static async getUserPokemon(access_token: string) {
        let result = await axios.get(BACKEND + "/api/pokemon", {
            headers: {
                "Authorization": "Bearer " + access_token
            }
        })
        if (result.status === 200) {
            return result.data
        }

        return false
    }


    // USER

    static async logout(access_token: string) {
        let result = await axios.get(BACKEND + "/auth/twitch/logout", {
            headers: {
                "Authorization": "Bearer " + access_token
            }
        })
        if (result.status === 200) {
            return true
        }

        return false
    }

    static async validateToken(access_token: string) {

        let result = await axios.get(BACKEND + "/auth/twitch/validate", {
            withCredentials: true,
            headers: {
                "Authorization": "Bearer " + access_token
            }
        })

        if (result.status === 201 || result.status === 200) {
            return result.data
        }

        return false
    }

    static async getSchedule() {
        let result = await axios.get(BACKEND + "/api/schedule")
        if (result.status === 200) {
            return result.data
        }

        return false
    }

    static async getUserData(access_token: string) {
        let result = await axios.get(BACKEND + "/api/users/info", {
            headers: { "Authorization": "Bearer " + access_token }
        })
        if (result.status === 200) {
            return result.data
        }

        return null
    }

    static async getUserPurchased(access_token: string) {
        let result = await axios.get(BACKEND + "/api/items/purchased", {
            headers: { "Authorization": "Bearer " + access_token }
        })
        if (result.status === 200) {
            return result.data
        }

        return []
    }

    static async getAllShopItems() {
        let result = await axios.get(BACKEND + "/api/items")
        if (result.status === 200) {
            return result.data
        }

        return []
    }

    static async purchaseItem(item_id: number, access_token: string) {
        let result = await axios.post(BACKEND + "/api/items/buy/" + item_id, {}, {
            headers: { "Authorization": "Bearer " + access_token }
        })
        if (result.status === 200) {
            return result.data
        }

        return []
    }

    static async getAllAnnouncements() {
        let result = await axios.get(BACKEND + "/api/announcements")
        if (result.status === 200) {
            return result.data
        }

        return []
    }

    static async getMaintenanceStatus() {
        let result = await axios.get(BACKEND + "/api/whitelist/status")
        if (result.status === 200) {
            return result.data
        }

        return null
    }

    static async checkWhitelist(ip_address: string) {
        let result = await axios.get(BACKEND + `/api/whitelist?ip=${ip_address}`)
        if (result.status === 200) {
            return result.data
        }

        return null
    }

    static async getLeaderboard(){
        let result = await axios.get(BACKEND + `/api/users/leaderboard/all`)
        if (result.status === 200) {
            return result.data
        }

        return []
    }

    // EXTERNAL    
    static async getPublicIP() {
        let endpoint = process.env.REACT_APP_PUBLIC_IP

        if (endpoint) {
            let result = await axios.get(endpoint)
            if (result.status === 200) {
                return result.data['origin']
            }
        }

        return null
    }
}