import { useEffect, useState } from 'react'
import { getWindowSize } from '../../scripts/utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faClose } from '@fortawesome/free-solid-svg-icons'
import { useCookies } from 'react-cookie';
import ProfileTooltip from '../ProfileTooltip'
import { User } from '../../scripts/types'
import TwitchRequests from '../../scripts/twitch_requests'
import Requests from '../../scripts/requests'
import { useDispatch, useSelector } from 'react-redux'
import { setAccessToken, setLoggedIn, setUserData } from '../../redux/features/userSlice'
import { NavLink } from "react-router-dom";
import _ from 'lodash'
import { setInLive } from '../../redux/features/channelSlice'


import "../../css/layout/Header.scss"
import MainMenu from '../MainMenu';


function Header() {

    const dispatcher = useDispatch()

    const [cookies, setCookie, removeCookie] = useCookies();
    const [windowSize, setWindowSize] = useState(getWindowSize());

    const [navMobile, setNavMobile] = useState(false)

    //const [accessToken, setAccessToken] = useState()
    const [profileInfo, setProfileInfo] = useState(false)
    const [scrolled, setScrolled] = useState(false)
    const [openMenu, setOpenMenu] = useState(false)
    const [dataLoading, setDataLoading] = useState(true)


    const userData: User = useSelector((state: any) => state.user.userData)
    const isLoggedIn: boolean = useSelector((state: any) => state.user.loggedIn)
    const accessToken: string = useSelector((state: any) => state.user.accessToken)

    // Handle for tab switch
    useEffect(() => {
        const handleVisibilityChange = async () => {
          if (document.visibilityState === 'visible' && accessToken) { 
                try {
                    let result = await Requests.getUserData(accessToken)
                    if (result) {
                        dispatcher(setUserData(result))
                        setCookie("user_info", result)
                    }
                } catch (e) {
                    console.log(e);
                }
          }
        };
    
        document.addEventListener('visibilitychange', handleVisibilityChange);
    
        return () => {
          document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
      }, []);

    const preloadData = async (access_token: string) => {

        try {
            let resultValidation = await Requests.validateToken(accessToken)
            if (!resultValidation) {
                return
            }
            if (resultValidation['status'] === "refreshed") {
                access_token = resultValidation['access_token']
            }

            dispatcher(setLoggedIn(true))
            dispatcher(setAccessToken(access_token))
        } catch (e) {
            console.log(e);
        }

        try {
            let result = await Requests.getUserData(accessToken)
            if (result) {
                dispatcher(setUserData(result))
                setCookie("user_info", result)
            }
        } catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        let access_token = cookies["access_token"]
        let user_info = cookies['user_info']
        

        if (access_token) {
            dispatcher(setAccessToken(access_token))
        } else {
            dispatcher(setLoggedIn(false))
            setDataLoading(false)
        }


        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }

        function handleScroll() {
            if (window.scrollY > 0) {
                setScrolled(true)
            } else {
                setScrolled(false)
            }
        }

        window.addEventListener('scroll', handleScroll);
        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        if (accessToken) {
            preloadData(accessToken)
                .finally(() => setDataLoading(false))
        }
    }, [accessToken])

    useEffect(() => {
        if (accessToken) {
            TwitchRequests.getChannelStream(accessToken)
                .then(res => {
                    if ("data" in res) {
                        let status = false
                        if (res['data'].length > 0) {
                            status = true
                        }

                        dispatcher(setInLive(status))
                    }
                })
                .catch(err => console.log(err))

        }
    }, [accessToken])

    function logout() {
        Requests.logout(cookies['access_token'])
            .then(() => {
                removeCookie("access_token")
                removeCookie("refresh_token")
                removeCookie("user_info")
            })
            .catch(err => console.log(err))
            .finally(() => {
                window.location.reload()
            })
    }

    return (
        <div className={'header'}>
            <div className={scrolled ? "header-wrapper scrolled" : "header-wrapper"}>

                <div className='menu-wrapper-btn'>
                    <a className='menu-btn' onClick={() => setOpenMenu(prev => !prev)}>
                        {
                            openMenu ?
                                <FontAwesomeIcon icon={faClose} />
                                :
                                <FontAwesomeIcon icon={faBars} />
                        }

                    </a>

                    <MainMenu 
                        isLoggedIn={isLoggedIn}
                        openMenu={openMenu}
                        setOpenMenu={setOpenMenu}
                        userData={userData}
                    />

                </div>


                <NavLink className="logo" to={"/"}>
                    <img src='img/logo-cut.png' alt='logo-justdock' />
                </NavLink>


                <div className="profile-wrapper">
                    {!dataLoading && !isLoggedIn &&
                        <a className="twitch-login" href={process.env.REACT_APP_BACKEND_URL + "/auth/twitch"} target='_self'>
                            <img src="/img/twitch-black.png" alt="twitch-logo" width={24} height={24} />
                            {windowSize.innerWidth > 960 && <p>Accedi</p>}
                        </a>
                    }

                    {!dataLoading && isLoggedIn &&
                        <div className='profile-wrapper-data'>
                            {"coins" in userData && !_.isEmpty(userData) && windowSize.innerWidth > 960 &&
                                <p className='user-balance'>{userData.coins.toFixed(1)}
                                    <img className='logo-jestoni' src='img/jestoni.png' />
                                </p>
                            }

                            {(isLoggedIn && userData) &&

                                windowSize.innerWidth > 960 ?
                                <NavLink
                                    to={"/profile"}
                                    className={"profile-btn " + (userData.is_subscriber ? "profile-btn-sub" : "")}
                                    style={{ backgroundImage: 'url("' + userData.profile_image_url + '")' }}
                                >
                                </NavLink>
                                :
                                isLoggedIn &&
                                <a
                                    onClick={() => setProfileInfo(prev => !prev)}
                                    className={"profile-btn " + (userData.is_subscriber ? "profile-btn-sub" : "")}
                                    style={{ backgroundImage: 'url("' + userData.profile_image_url + '")' }}
                                >
                                </a>
                            }

                            {!dataLoading &&
                                <ProfileTooltip
                                    isMobile={windowSize.innerWidth < 960}
                                    open={profileInfo}
                                    userData={userData}
                                    logout={logout}
                                    closeTooltip={setProfileInfo}
                                />
                            }
                        </div>
                    }
                </div>
            </div>


            {windowSize.innerWidth < 960 &&
                <div className={navMobile ? "nav-mobile-wrapper nav-mobile-open" : "nav-mobile-wrapper"}>

                    {"coins" in userData && !_.isEmpty(userData) &&
                        <div className="mobile-user-balance">
                            <p>Bilancio: </p>
                            <p className='amount'>{userData.coins.toFixed(1)}
                                <img className='logo-jestoni' src='img/jestoni.png' />
                            </p>
                        </div>
                    }

                    <ul className="nav-mobile">
                        <NavLink
                            onClick={() => setNavMobile(prev => !prev)}
                            to="/"
                            className={({ isActive }) => (isActive ? 'nav-active' : '')}
                        >
                            Home
                        </NavLink>
                        <NavLink
                            onClick={() => setNavMobile(prev => !prev)}
                            to='/shop'
                            className={({ isActive }) => (isActive ? 'nav-active' : '')}
                        >
                            Shop
                        </NavLink>
                        <NavLink
                            onClick={() => setNavMobile(prev => !prev)}
                            to='/info'
                            className={({ isActive }) => (isActive ? 'nav-active' : '')}
                        >
                            Info
                        </NavLink>
                    </ul>

                    {!_.isEmpty(userData) && !_.isEmpty(userData) &&
                        <ul className="nav-mobile-buttons">
                            <NavLink
                                to="/purchase-history"
                                onClick={() => setNavMobile(prev => !prev)}
                                className={({ isActive }) => (isActive ? 'nav-active-purchase' : '')}
                            >
                                Storico riscatti
                            </NavLink>
                            <a onClick={() => logout()}>Logout</a>
                        </ul>
                    }
                </div>

            }

        </div>
    )
}

export default Header