import React from 'react'

import "../../css/layout/Footer.scss"

function Footer() {
  return (
    <div className='footer'>
        <p>Copyright © {new Date().getFullYear()}</p>
    </div>
  )
}

export default Footer