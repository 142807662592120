import { useEffect, useState } from 'react'
import '../css/components/LivePopup.scss'

function LivePopup() {

    const [icon, setIcon] = useState(false)

    useEffect(()=>{
        const iconInterval = setInterval(()=>{
            setIcon(prev => !prev)
        }, 1000)

        return ()=>{
            clearInterval(iconInterval)
        }
    }, [])

    return (
        <a className='live-popup' href={'https://twitch.tv/' + process.env.REACT_APP_CHANNEL} target='_blank'>
            <p>{icon ? "●" : "○"} IN LIVE</p>
        </a>
    )
}

export default LivePopup