import React, { useState } from 'react'

import "../css/components/Slider.scss"

export interface SliderItem {
    title: string
    src: string
    author: string
    thumbnail: string
    date: string
}

interface SliderProps {
    items: SliderItem[]
    type: "img" | "video"
}

function Slider({ items, type }: SliderProps) {

    const [checked, setChecked] = useState(0)

    const isChecked = (idx: number) => {
        if (idx === checked) return true
        return false
    }

    return (
        <div className="slider-container">
            <div className="clip-metadata">
                <h5 className='clip-author'>{items[checked]['author']}</h5>
                <h5 className='clip-date'>({items[checked]['date']})</h5>
            </div>
            <h4 className='card-title'>{items[checked]['title']}</h4>
            {
                items.map((el, idx) => {
                    return (
                        <input
                            key={idx}
                            type="radio"
                            name="slider"
                            id={"item-" + (idx + 1)}
                            onChange={() => setChecked(idx)}
                            checked={isChecked(idx)}
                        />
                    )
                })
            }

            <div className="cards">
                {
                    items.map((el, idx) => {
                        return (
                            <label key={idx} className="card" htmlFor={"item-" + (idx + 1)} id={"song-" + (idx + 1)}>
                                {
                                    type === "img" ?
                                        <img src={el.src} />
                                        :
                                        isChecked(idx) ?
                                        <iframe
                                            src={el.src + "&parent=lamendock.com"}
                                            allowFullScreen
                                            >
                                        </iframe>
                                        :
                                        <img src={el.thumbnail} />
                                }
                            </label>
                        )
                    })
                }
            </div>

        </div>
    )
}

export default Slider