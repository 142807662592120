import axios from "axios";

export class AdminRequests {

    // Pokemon

    static async getAllUsersPokemon(access_token: string) {
        let response = await axios.get(process.env.REACT_APP_BACKEND_URL + "/api/pokemon/users", {
            headers: {
                "Authorization": "Bearer " + access_token
            }
        })

        return response.data
    }

    static async getAllPokemon(access_token: string) {
        let response = await axios.get(process.env.REACT_APP_BACKEND_URL + "/api/pokemon/all?no_image=true", {
            headers: {
                "Authorization": "Bearer " + access_token
            }
        })

        return response.data
    }


    static async deletePokemonFromList(access_token: string, pokemon_id: number) {
        let response = await axios.delete(process.env.REACT_APP_BACKEND_URL + "/api/pokemon/" + pokemon_id, {
            headers: {
                "Authorization": "Bearer " + access_token
            }
        })

        return response.data
    }

    static async deletePokemonFromUserList(access_token: string, user: string, pokemon: string) {
        console.log(user, pokemon);

        let response = await axios.delete(process.env.REACT_APP_BACKEND_URL + "/api/pokemon/user/remove", {
            headers: {
                "Authorization": "Bearer " + access_token
            },
            data: {
                "pokemon": pokemon,
                "user": user
            }
        })

        return response.data
    }

    static async addNewPokemon(access_token: string, payload: FormData) {
        let response = await axios.post(process.env.REACT_APP_BACKEND_URL + "/api/pokemon", payload, {
            headers: {
                "Content-Type": "multipart/form-data",
                "Authorization": "Bearer " + access_token
            }
        })

        return response.data
    }

    static async addPokemonToUser(access_token: string, payload: FormData) {
        let response = await axios.post(process.env.REACT_APP_BACKEND_URL + "/api/pokemon/user", payload, {
            headers: {
                "Content-Type": "multipart/form-data",
                "Authorization": "Bearer " + access_token
            }
        })

        return response.data
    }


    // Schedule
    static async updateSchedule(access_token: string, payload: FormData) {
        let response = await axios.put(process.env.REACT_APP_BACKEND_URL + "/api/schedule", payload, {
            headers: {
                "Content-Type": "multipart/form-data",
                "Authorization": "Bearer " + access_token
            }
        })

        return response.data
    }

    // Announcements
    static async addNewAnnouncement(access_token: string, payload: FormData) {
        let response = await axios.post(process.env.REACT_APP_BACKEND_URL + "/api/announcements", payload, {
            headers: {
                "Content-Type": "multipart/form-data",
                "Authorization": "Bearer " + access_token
            }
        })

        return response.data
    }

    static async updateAnnouncement(access_token: string, announcement_id: number, payload: FormData) {
        let response = await axios.patch(process.env.REACT_APP_BACKEND_URL + "/api/announcements/" + announcement_id,
            payload,
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                    "Authorization": "Bearer " + access_token
                }
            }
        )
        return response.data
    }

    static async removeAnnouncement(access_token: string, announcement_id: number) {
        let response = await axios.delete(process.env.REACT_APP_BACKEND_URL + "/api/announcements/" + announcement_id, {
            headers: {
                "Authorization": "Bearer " + access_token
            }
        })
        return response.data
    }

    // Shop Items
    static async getAllRedeems(access_token: string) {
        let response = await axios.get(process.env.REACT_APP_BACKEND_URL + "/api/items/redeems", {
            headers: {
                "Authorization": "Bearer " + access_token
            }
        })
        return response.data
    }


    static async freezeUnfreezeItem(access_token: string, item_id: number, status: boolean) {

        let formData = new FormData()
        formData.set("freeze", status.toString())
        let result = await axios.post(process.env.REACT_APP_BACKEND_URL + "/api/items/freeze/" + item_id,
            formData,
            {
                headers: {
                    "Authorization": "Bearer " + access_token,
                    "Content-Type": "multipart/form-data"
                }
            }
        )
        if (result.status === 200) {
            return result.data
        }

        return false
    }

    static async addNewItem(access_token: string, payload: FormData) {
        let response = await axios.post(process.env.REACT_APP_BACKEND_URL + "/api/items", payload, {
            headers: {
                "Authorization": "Bearer " + access_token,
                "Content-Type": "multipart/form-data"
            }
        })

        return response.data
    }

    static async updateItem(access_token: string, item_id: number, payload: FormData) {
        let response = await axios.patch(process.env.REACT_APP_BACKEND_URL + "/api/items/" + item_id,
            payload,
            {
                headers: {
                    "Authorization": "Bearer " + access_token,
                    "Content-Type": "multipart/form-data"
                }
            }
        )
        return response.data
    }


    static async removeItem(access_token: string, item_id: number) {
        let response = await axios.delete(process.env.REACT_APP_BACKEND_URL + "/api/items/" + item_id, {
            headers: {
                "Authorization": "Bearer " + access_token
            }
        })
        return response.data
    }

    // BETTING
    static async getBettingTemplates(access_token: string) {
        let response = await axios.get(process.env.REACT_APP_BACKEND_URL + "/api/bet/templates", {
            headers: {
                "Authorization": "Bearer " + access_token
            }
        })
        return response.data
    }

    static async getBettingStatus(access_token: string) {
        let response = await axios.get(process.env.REACT_APP_BACKEND_URL + "/api/bet/status", {
            headers: {
                "Authorization": "Bearer " + access_token
            }
        })
        return response.data
    }

    static async openBet(access_token: string, payload: any) {
        let response = await axios.post(process.env.REACT_APP_BACKEND_URL + "/api/bet/open", payload,
            {
                headers: {
                    "Authorization": "Bearer " + access_token
                }
            })
        return response.data
    }

    static async closeBet(access_token: string, choices: string[]) {
        let response = await axios.post(process.env.REACT_APP_BACKEND_URL + "/api/bet/close", {
            "args": choices
        },
            {
                headers: {
                    "Authorization": "Bearer " + access_token
                }
            })
        return response.data
    }
}