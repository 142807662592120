import React, { useEffect, useState } from 'react'
import '../css/components/Leaderboard.scss'
import Requests from '../scripts/requests'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'

interface UserData {
    username: string
    coins: number
    position: number
}


function Leaderboard() {

    const [leaderboard, setLeaderboard] = useState<UserData[]>([])
    const [search, setSearch] = useState<string>("");

    useEffect(() => {
        Requests.getLeaderboard()
            .then(res => {
                let leaderboard: UserData[] = []
                res.forEach((e: UserData, idx: number) => {
                    leaderboard.push({
                        "username": e.username,
                        "coins": e.coins,
                        "position": idx + 1
                    })
                });
                setLeaderboard(leaderboard)
            })
    }, [])


    const inputChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value
        setSearch(value)
    }

    return (
        <div className='leaderboard-wrapper'>
            <img alt='leaderboard_title' src='img/LEADERBOARD.png' className='leaderboard-title' />
            <div className="search-box">
                <input type="text" className='search-input' onChange={inputChanged} />
                <FontAwesomeIcon icon={faSearch} className='search-icon' />
            </div>
            <ul id="leaderboard">
                {
                    search === "" ?
                        leaderboard.map((e: UserData, idx: number) => {
                            return <li className='leaderboard-el' key={idx}>
                                <p>{e.position}. {e.username}</p>

                                <span>
                                    <p>{Number.isInteger(e.coins) ? e.coins : e.coins.toFixed(1)}</p>
                                    <img alt='jestoni_logo' className='logo-jestoni' src='img/jestoni.png' />
                                </span>
                            </li>
                        })
                        :
                        leaderboard.filter((e: UserData) => e.username.includes(search!)).map((e: UserData, idx: number) => {
                            return <li className='leaderboard-el' key={idx}>
                                <p>{e.position}. {e.username}</p>
                                <span>
                                    <p>{Number.isInteger(e.coins) ? e.coins : e.coins.toFixed(1)}</p>
                                    <img alt='jestoni_logo' className='logo-jestoni' src='img/jestoni.png' />
                                </span>
                            </li>
                        })}
            </ul>
        </div>
    )
}

export default Leaderboard