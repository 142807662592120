import React, { useEffect, useState } from 'react'
import { Item, User } from '../scripts/types'
import { useDispatch, useSelector } from 'react-redux'
import "../css/Shop.scss"
import '../css/components/ShopCard.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSnowflake, faStar } from '@fortawesome/free-solid-svg-icons'
import ShopOverlay from '../components/ShopOverlay'
import Requests from '../scripts/requests'
import { getItems, setCurrentItem } from '../redux/features/itemsSlice'
import CircleLoading from '../components/CircleLoading'
import ShopCard from '../components/ShopCard'

/*
const exampleRewards: ShopCard[] = [
  {
    "title": "Cambia voce",
    "description": "Riscatta questa card e fai cambiare la voce a JustSpeetz per 3 minuti",
    "price": 5500
  },
  {
    "title": "Siciliano",
    "description": "Riscatta questa card e fai parlare JustSpeetz in dialetto siciliano per 5 minuti",
    "price": 8500,
    "forSub": true
  },
  {
    "title": "JustCroccantino",
    "description": "Riscatta questa card per far mangiare a JustSpeetz un croccantino di Loki",
    "price": 1350
  },
  {
    "title": "Buono Amazon 10€",
    "description": "Riscatta questa card per ottenere un buono amazon dal valore di 10€",
    "price": 112500
  },
]
*/
/*
interface ShopProps {
  isSubscriber: boolean
}
*/

function Shop() {

  const dispatcher = useDispatch()
  const items: Item[] = useSelector((state: any) => state.items.items)
  const currentItem: Item = useSelector((state: any) => state.items.currentItem)
  const isLoggedIn: boolean = useSelector((state: any) => state.user.loggedIn)
  const userData: User = useSelector((state: any) => state.user.userData)
  //const isSubscriber: boolean = useSelector((state: any) => state.user.subscriber)

  const [dataLoading, setDataLoading] = useState<boolean>(true)


  useEffect(() => {

    Requests.getAllShopItems()
      .then(res => {
        dispatcher(getItems(res))
      })
      .catch(err => console.log(err))
      .finally(() => setDataLoading(false))

  }, [])

  return (
    <div className='content-wrapper'>
      {currentItem &&
        <ShopOverlay
          question={`Sicuro di voler riscattare l'oggetto "${currentItem.title}"?`}
        />
      }


      {dataLoading ?
        <div className="empty-container">
          <CircleLoading color='#fff' />
        </div>

        :

        items.length === 0 ?
          <div className="empty-container">
            <h2 className='shop-empty'>Nessun elemento da visualizzare</h2>
          </div>
          :

          <div className="shop-cards-grid">
            {items.map((el, idx) => {
              return (
                <ShopCard
                  item={el}
                  userData={userData}
                  isLoggedIn={isLoggedIn}
                />
              )

            })}
          </div>
      }
    </div>
  )
}

export default Shop