import { useEffect, useState } from 'react'

import "../css/Home.scss"
import { EnglishDayToItalian, dateFormat, getWindowSize } from '../scripts/utils'
import Slider, { SliderItem } from '../components/Slider'
import Requests from '../scripts/requests'
import CircleLoading from '../components/CircleLoading'
import { Announcement, Clip } from '../scripts/types'
import TwitchRequests from '../scripts/twitch_requests'
import { useSelector } from 'react-redux'
import Leaderboard from '../components/Leaderboard'

const urls = [
  {
    "title": "TU SAI CHI... MI FA... mi fa.",
    "src": "https://production.assets.clips.twitchcdn.net/p_LMLvMFzPEhrNesYdOV9g/AT-cm%7Cp_LMLvMFzPEhrNesYdOV9g.mp4?sig=17795b8f58cf08305d0a98289efd7140a70159df&token=%7B%22authorization%22%3A%7B%22forbidden%22%3Afalse%2C%22reason%22%3A%22%22%7D%2C%22clip_uri%22%3A%22https%3A%2F%2Fproduction.assets.clips.twitchcdn.net%2Fp_LMLvMFzPEhrNesYdOV9g%2FAT-cm%257Cp_LMLvMFzPEhrNesYdOV9g.mp4%22%2C%22device_id%22%3A%22BEUjR2eZuSUzRKOQs5mVbDK0yPdKOq14%22%2C%22expires%22%3A1698422341%2C%22user_id%22%3A%22598167314%22%2C%22version%22%3A2%7D"
  },
  {
    "title": "NON! CI! CREDO!",
    "src": "https://production.assets.clips.twitchcdn.net/AT-cm%7CwnM70r4VnQZyTKQ6FCF8bA.mp4?sig=9b28405acad9530583646b61d6d2363c0070e0ea&token=%7B%22authorization%22%3A%7B%22forbidden%22%3Afalse%2C%22reason%22%3A%22%22%7D%2C%22clip_uri%22%3A%22https%3A%2F%2Fproduction.assets.clips.twitchcdn.net%2FAT-cm%257CwnM70r4VnQZyTKQ6FCF8bA.mp4%22%2C%22device_id%22%3A%22BEUjR2eZuSUzRKOQs5mVbDK0yPdKOq14%22%2C%22expires%22%3A1698423673%2C%22user_id%22%3A%22598167314%22%2C%22version%22%3A2%7D",
  },
  {
    "title": "sono stupido",
    "src": "https://production.assets.clips.twitchcdn.net/AT-cm%7CkQo5kN0sujcKg-Uq5CBI6A.mp4?sig=86ef6388daaae6f68b8ba0e00d0d2d72872b2097&token=%7B%22authorization%22%3A%7B%22forbidden%22%3Afalse%2C%22reason%22%3A%22%22%7D%2C%22clip_uri%22%3A%22https%3A%2F%2Fproduction.assets.clips.twitchcdn.net%2FAT-cm%257CkQo5kN0sujcKg-Uq5CBI6A.mp4%22%2C%22device_id%22%3A%22BEUjR2eZuSUzRKOQs5mVbDK0yPdKOq14%22%2C%22expires%22%3A1698423886%2C%22user_id%22%3A%22598167314%22%2C%22version%22%3A2%7D"
  }

]

const images = [
  {
    "src": "https://images.unsplash.com/photo-1530651788726-1dbf58eeef1f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=882&q=80",
    "title": "IMG 1"
  },
  {
    "src": "https://images.unsplash.com/photo-1559386484-97dfc0e15539?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1234&q=80",
    "title": "IMG 2"
  },
  {
    "src": "https://images.unsplash.com/photo-1533461502717-83546f485d24?ixlib=rb-1.2.1&auto=format&fit=crop&w=900&q=60",
    "title": "IMG 3"
  }
]

function Home() {

  const accessToken: string = useSelector((state: any) => state.user.accessToken)

  const [windowSize, setWindowSize] = useState(getWindowSize())
  const [videoIdx, setVideoIdx] = useState(0)

  const [dataLoading, setDataLoading] = useState(true)
  const [announcements, setAnnouncements] = useState<Announcement[]>([])
  const [schedule, setSchedule] = useState<any>({})

  const [clips, setClips] = useState<Clip[]>([])
  const [sliderItems, setSliderItems] = useState<SliderItem[]>([])
  const [clipInError, setClipInError] = useState<boolean>(false)

  useEffect(() => {
    if (accessToken) {
      TwitchRequests.getChannelClips(accessToken)
        .then((res) => {

          setClips(res["data"].reverse())

          const items = res["data"].reverse().map((el: Clip) => {
            return {
              "title": el.title,
              "src": el.embed_url,
              "author": el.creator_name,
              "thumbnail": el.thumbnail_url,
              "date": new Date(el.created_at).toLocaleDateString() + " - " + new Date(el.created_at).toLocaleTimeString() 
            }
          }).slice(-3)
          setSliderItems(items)

        })
        .catch(err => {
          setClipInError(true)
          console.log(err)
        })
    }
  }, [accessToken])

  useEffect(() => {

    async function loadData() {
      await Requests.getSchedule()
        .then((res) => {     
          setSchedule(res)
        })
        .catch(err => console.log(err))

      await Requests.getAllAnnouncements()
        .then(res => {
          setAnnouncements(res)
        })
        .catch(err => console.log(err))


    }

    loadData()
      .finally(
        () => setDataLoading(false)
      )

    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [])


  function reverseScheduleDate(dateString : string){
    var p = dateString.split('_');
    var anno = p[0];
    var mese = p[1];
    var giorno = p[2];
    return giorno + "/" + mese + "/" + anno
  }

  return dataLoading ?
    <div className='content-wrapper'>
      <CircleLoading color='#fff' />
    </div>
    :
    (
      <div className='content-wrapper'>

        {
          schedule &&
          <div id="schedule">
            <img src='img/SCHEDULE.png' alt='schedule-title'/>
            <h5>Aggiornata il: {reverseScheduleDate(schedule["update_time"])}</h5>
            <ul>
              {
                Object.keys(schedule["schedule"]).map((el: any, idx) => {                  
                  return(
                     <li className="schedule-day" key={idx}>
                      <p className='schedule-day-title'>{EnglishDayToItalian(el).toUpperCase()}</p>
                      {schedule["schedule"][el]["title"] ? 
                        <p>{schedule["schedule"][el]["title"]}</p> 
                        : 
                        <p className='no-stream-day'>No stream</p>}
                      <p>{schedule["schedule"][el]["time"] ? schedule["schedule"][el]["time"] : "-"}</p>
                     </li>
                  )
                })
              }
            </ul>
          </div>
        }

        {announcements.length > 0 &&
          <>
            <img id='latest-announcements-title' src='img/NEWS.png' alt='news-title'/>
            <ul className='latest-announcements'>
              {announcements.map((el, idx) => {
                return (
                  <li key={idx}>
                    <div className="announcement-header">
                      <h3>{el.title}</h3>
                      {el.date && <p>{dateFormat(el.date, true)}</p>}
                    </div>

                    <p>{el.description}</p>
                  </li>
                )
              })}
            </ul>
          </>
        }

        <Leaderboard />

        {!clipInError && sliderItems.length > 0 &&
          <div className="latest-clips-wrapper">
            <img id='latest-clips-title' src='img/CLIP.png' alt='clips-title'/>
            <Slider items={sliderItems} type='video' />
            <a 
              href={'https://twitch.tv/'+ process.env.REACT_APP_CHANNEL + "/clips"}
              target='_blank'  
              className='other-clips'
            >Altre clip {">"}</a>
          </div>
        }

      </div>
    )
}

export default Home