import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'

import '../css/Profile.scss'
import { User } from '../scripts/types'
import Requests from '../scripts/requests'
import { useCookies } from 'react-cookie'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAdd, faRemove, faSkull } from '@fortawesome/free-solid-svg-icons'
import PokemonTypeBadge from '../components/PokemonTypeBadge'
import CircleLoading from '../components/CircleLoading'

interface Pokemon {
    name: string
    api_data: string
    points: number
    type: string
    died: boolean
}

function Profile() {

    const [allPokemon, setAllPokemon] = useState<Array<Pokemon>>([])
    const [myPokemon, setMyPokemon] = useState<Array<Pokemon>>([])
    const [pokemonTeam, setPokemonTeam] = useState<Array<Pokemon>>([])
    const [teamLoaded, setTeamLoaded] = useState(false)
    const [pokemonLoading, setPokemonLoading] = useState(true)
    const [inSearch, setInSearch] = useState(false)

    const userData: User = useSelector((state: any) => state.user.userData)
    const accessToken = useSelector((state: any) => state.user.accessToken)
    const searchInput = useRef<any>()

    const [cookies, setCookie, removeCookie] = useCookies();

    useEffect(() => {
        if (accessToken) {
            Requests.getUserPokemonTeam(accessToken)
                .then(res => {
                    setPokemonTeam(res)
                })
                .catch(err => null)
                .finally(() => setTeamLoaded(true))
        }
    }, [accessToken])

    useEffect(() => {
        if (accessToken) {
            Requests.getUserPokemon(accessToken)
                .then(res => {
                    setMyPokemon(res)
                    setAllPokemon(res)
                })
                .catch(err => null)
                .finally(() => setPokemonLoading(false))
        }
    }, [accessToken])


    const removePokemon = (idx: number, pokemonName: string = "") => {
        let name = pokemonName ? pokemonName : pokemonTeam[idx].name

        Requests.removePokemonFromTeam(name, accessToken)
            .then(res => {
                Requests.getUserPokemonTeam(accessToken)
                    .then(res => {
                        setPokemonTeam(res)
                    })
            })
            .catch(err => console.log(err))
    }

    const addPokemonInTeam = (pokemonName: string, slot: number | null = null) => {
        if (!slot) {
            Requests.addPokemonToTeam(pokemonName, accessToken)
                .then(res => {
                    Requests.getUserPokemonTeam(accessToken)
                        .then(res => {
                            setPokemonTeam(res)
                        })
                })
                .catch(err => console.log(err))
        } else {
            Requests.addPokemonToTeamSlot(pokemonName, slot, accessToken)
                .then(res => {
                    Requests.getUserPokemonTeam(accessToken)
                        .then(res => {
                            setPokemonTeam(res)
                        })
                })
                .catch(err => console.log(err))
        }

    }

    const searchPokemon = (ev: any) => {
        let searched = allPokemon.filter((e: Pokemon) => {
            return e.name.includes(ev.target.value.toLowerCase())
        })

        setMyPokemon(searched)
        setInSearch(true)
    }

    const pokemonTypes = (type: string) => {
        return type.split(",")
    }


    const logout = ()=>{
        Requests.logout(accessToken)
        .finally(()=>{
            removeCookie("user_info")
            removeCookie("access_token")
            window.location.href = process.env.REACT_APP_CLIENT_URL!
        })
    }

    return (
        <div className='content-wrapper'>

            <div className="profile-data">
                
                <img id='profile-title' src='img/PROFILO.png' alt='news-title'/>
                <div
                    className='profile-image'
                    style={{ backgroundImage: `url("${userData.profile_image_url}")` }}
                >
                </div>
                <ul className='profile-info'>
                    <li>
                        <p>TWITCH ID: </p>
                        <p>{userData.id}</p>
                    </li>
                    <li>
                        <p>USERNAME: </p>
                        <p>{userData.display_name}</p>
                    </li>
                    <li>
                        <p>BILANCIO ATTUALE: </p>
                        <p>{userData.coins}</p>
                    </li>
                    <li>
                        <p>ISCRITTO: </p>
                        <p>{userData.is_subscriber ? "SI" : "NO"}</p>
                    </li>
                    <li>
                        <p>ADMIN</p>
                        <p>{userData.is_admin ? "SI" : "NO"}</p>
                    </li>
                </ul>

                <a className="logout-btn" onClick={()=>logout()}>LOGOUT</a>
            </div>
            
            { //POKEMON
            /*
            <div className="pokemon">
                <div className="pokedex">
                    <h3 className='pokedex-title'>I miei pokemon</h3>
                    <div className='pokedex-searchbar'>
                        <input ref={searchInput} type="text" onChange={searchPokemon} />
                        {
                            inSearch ?
                                <a onClick={() => {
                                    setMyPokemon(allPokemon)
                                    setInSearch(false)
                                    if (searchInput.current) searchInput.current.value = ""
                                }}>ANNULLA</a>
                                :
                                <a>CERCA</a>
                        }
                    </div>

                    {pokemonLoading ?
                        <div className="pokedex-list-loading">
                            <CircleLoading color='#fff' />

                        </div>

                        :
                        myPokemon.length === 0 ?
                            <div className="pokedex-list-loading">
                                <h3>Nessun Pokemon disponibile</h3>
                            </div>
                            :
                            <ul className="pokedex-list">
                                {
                                    myPokemon.map((el, idx) => {

                                        let icon: any = JSON.parse(el.api_data)['sprites']['front_default']

                                        return (
                                            <li key={idx}>
                                                <div
                                                    className='pokemon-icon'
                                                    style={{ backgroundImage: `url("${icon}")` }}
                                                    draggable={!el.died}
                                                    onDragStart={(e: React.DragEvent<HTMLElement>) => {
                                                        e.dataTransfer.effectAllowed = "move"
                                                        e.dataTransfer.setData("pokemonName", el.name)
                                                        e.currentTarget.className = "pokemon-icon dragged-pokemon"
                                                    }}
                                                ></div>
                                                <p className='pokemon-title'>{el['name']}</p>
                                                <div className='pokemon-type'>
                                                    {
                                                        pokemonTypes(el.type).map((el, idx) => {
                                                            return <PokemonTypeBadge type={el} />
                                                        })

                                                    }
                                                </div>
                                                <p className='pokemon-points'>{el['points']}</p>

                                                <div className="pokedex-item-buttons">
                                                    {
                                                        pokemonTeam.length > 0 &&
                                                            el.died ?
                                                            <FontAwesomeIcon
                                                                icon={faSkull}
                                                                size='2x'
                                                                color='#fff'
                                                            />
                                                            :
                                                            pokemonTeam.some(e => (e !== null) ? (e.name === el['name']) : false) ?
                                                                <FontAwesomeIcon
                                                                    icon={faRemove}
                                                                    size='2x'
                                                                    color='red'
                                                                    cursor={"pointer"}
                                                                    onClick={() => removePokemon(-1, el['name'])}
                                                                />
                                                                :
                                                                pokemonTeam.some(e => (e === null)) ?
                                                                    <FontAwesomeIcon
                                                                        icon={faAdd}
                                                                        cursor={"pointer"}
                                                                        size='2x'
                                                                        color='green'
                                                                        onClick={() => addPokemonInTeam(el['name'])}
                                                                    />
                                                                    :
                                                                    null
                                                    }

                                                </div>
                                            </li>
                                        )
                                    })
                                }
                            </ul>

                    }
                </div>
                
                <div className="user-team">
                    <h3 className='pokemon-team-title'>Team</h3>
                    <div className="pokemon-team">
                        {teamLoaded &&
                            [...Array(6)].map((el, idx) => {
                                if (pokemonTeam[idx]) {
                                    let pokemon: any = pokemonTeam[idx].api_data

                                    return (
                                        <div
                                            key={idx}
                                            onClick={() => removePokemon(idx)}
                                            className="pokemon-slot"
                                            style={{ backgroundImage: `url("${pokemon['sprites']['front_default']}")` }}
                                        ></div>
                                    )
                                } else {
                                    return (
                                        <div
                                            key={idx}
                                            className="pokemon-slot"
                                            onDragOver={(e: React.DragEvent<HTMLElement>) => {
                                                e.preventDefault();
                                                e.dataTransfer.dropEffect = "move"
                                                e.currentTarget.className = "pokemon-slot pokemon-slot-dragged"
                                            }}

                                            onDragLeave={(e: React.DragEvent<HTMLElement>) => {
                                                e.preventDefault();
                                                e.currentTarget.className = "pokemon-slot"
                                            }}

                                            onDrop={(e: React.DragEvent<HTMLElement>) => {
                                                e.preventDefault();
                                                e.currentTarget.className = "pokemon-slot"

                                                let pokemonName = e.dataTransfer.getData("pokemonName")
                                                if (teamLoaded && !pokemonTeam.some((el: Pokemon) => el === null ? false : el.name === pokemonName))
                                                    addPokemonInTeam(pokemonName, idx)
                                            }}
                                        ></div>
                                    )
                                }
                            })
                        }

                        {!teamLoaded &&
                            [...Array(6)].map((el, idx) => {
                                return (
                                    <div
                                        key={idx}
                                        className="pokemon-slot"
                                    ></div>
                                )
                            })
                        }

                    </div>
                </div>
                
            </div>
            */}
        </div>
    )
}

export default Profile