import axios from "axios";
import { format, sub } from 'date-fns';


const TWITCH_BASE_URL = "https://api.twitch.tv/helix"

export default class TwitchRequests {

    static async getChannelClips(access_token: string) {
        // Ottieni la data attuale
        const now: Date = new Date();
        const nowRFC3339: string = format(now, "yyyy-MM-dd'T'HH:mm:ssxxx"); // Data attuale in formato RFC3339

        // Ottieni la data di 3 mesi fa
        const prev: Date = sub(now, { months: 3 });
        const prevRFC3339: string = format(prev, "yyyy-MM-dd'T'HH:mm:ssxxx"); 

        const searchParams = new URLSearchParams();
        searchParams.append('broadcaster_id', process.env.REACT_APP_CHANNEL_ID || "");
        searchParams.append("started_at", prevRFC3339)
        searchParams.append("ended_at", nowRFC3339)

        let response = await axios.get(TWITCH_BASE_URL + "/clips", {
            params: searchParams,
            headers: {
                "Authorization": "Bearer " + access_token,
                "Client-ID": process.env.REACT_APP_CLIENT_ID
            }
        })

        if (response.status === 200){
            return response.data
        }

        return false
    }


    static async getChannelStream(access_token: string) {
        const searchParams = new URLSearchParams();
        searchParams.append('user_login', process.env.REACT_APP_CHANNEL || "");

        try {
            let response = await axios.get(TWITCH_BASE_URL + "/streams", {
                params: searchParams,
                headers: {
                    "Authorization": "Bearer " + access_token,
                    "Client-ID": process.env.REACT_APP_CLIENT_ID
                }
            })

            if (response.status === 200) {
                return response.data
            }
            return []
        } catch (err: any) {
            console.log(err);
            return []
        }

    }

    static async isUserSubscribed(access_token: string, user_id: string) {
        const BROADCASTER_ID = "635936223"

        const searchParams = new URLSearchParams();
        searchParams.append('broadcaster_id', BROADCASTER_ID);
        searchParams.append('user_id', user_id);

        try {
            let response = await axios.get(TWITCH_BASE_URL + "/subscriptions/user", {
                params: searchParams,
                headers: {
                    "Authorization": "Bearer " + access_token,
                    "Client-ID": process.env.REACT_APP_CLIENT_ID
                }
            })
            if (response.status === 200) {
                return response.data['data'].length > 0 ? response.data['data'][0] : null
            }
        } catch (err: any) {
            if ("response" in err) {
                if (err['response']['status'] === 404) {
                    console.error(`user not subscribed to ${BROADCASTER_ID}`);
                }
            } else {
                console.log("errore");
            }

            return null
        }

        return null
    }
}