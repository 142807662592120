export function generateAlphanumericString(size: number = 16) {
    const caratteri = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let risultato = '';
    for (let i = 0; i < size; i++) {
      risultato += caratteri.charAt(Math.floor(Math.random() * caratteri.length));
    }
    return risultato;
  }
  

export function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
}

export function dateFormat(dateIsoFormat: string, onlyDate: boolean = false) {
    var data = new Date(dateIsoFormat)
    var giorno = data.getDate();
    var mese = data.getMonth() + 1; // Mese inizia da 0, quindi aggiungiamo 1
    var anno = data.getFullYear();
    var ora = data.getHours();
    var minuti = data.getMinutes();
    var secondi = data.getSeconds();

    var dataFormattata = `${giorno.toString().padStart(2, '0')}/${mese.toString().padStart(2, '0')}/${anno}`;
    if (!onlyDate) {
        var dataFormattata = `${giorno.toString().padStart(2, '0')}/${mese.toString().padStart(2, '0')}/${anno} ${ora.toString().padStart(2, '0')}:${minuti.toString().padStart(2, '0')}:${secondi.toString().padStart(2, '0')}`;
    }
    return dataFormattata;
}

export function fillArrayWithNulls(arr: any[], maxSize: number): any[] {
    const filledArray = [...arr]; // Copia dell'array iniziale

    while (filledArray.length < maxSize) {
        filledArray.push(null);
    }

    return filledArray;
}


export function EnglishDayToItalian(day: string){
    switch (day){
        case "monday":
            return "lunedì"
        case "tuesday":
            return "martedì"
        case "wednesday":
            return "mercoledì"
        case "thursday":
            return "giovedì"
        case "friday":
            return "venerdì"
        case "saturday":
            return "sabato"
        case "sunday":
            return "domenica"
        default:
            return ""
    }
}