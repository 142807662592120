import { createSlice } from "@reduxjs/toolkit";


const channelSlice = createSlice({
    name: "channel",
    initialState:{
        inLive: false
    },
    reducers: {
        setInLive: (state, action) => {
            state.inLive = action.payload
        }
    }
})

export const {setInLive} = channelSlice.actions
export default channelSlice.reducer